.image-li-item {
    flex: 0 0 30%;
    outline: 1px solid;
}

.image-ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
    padding-inline-start: 10px;
}

@media (max-width: 800px) {
    .image-li-item {
        flex: 0 0 100%;
        outline: 1px solid;
        margin-top: 20px;
    }
}