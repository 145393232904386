.contactContainer {
    display: flex;
    flex-direction: column;
    background: #000000;    
    padding: 40px 50px;
    /* gap: 20px; */
    align-items: center;
}

.infoContainer {
    display: flex;
    width: 100%;
    padding-top: 20px;
    gap: 20px;
    /* align-items: flex-start; */
}

.leftContainer,
.rightContainer,
.middleContainer {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.leftContainer p,
.rightContainer p,
.middleContainer p {
    color: #2F5597;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
}

.leftContainer img,
.rightContainer img,
.middleContainer img {
    width: 50px;
    height: 50px;
}

/* .leftContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
} */

.title {
    text-align: center;
    display: flex;
}

.title p {
    color: #FFFFFF;
    font-size: 35px;
    font-family: "Times New Roman", Times, serif;
    font-weight: 900;
    text-transform: uppercase;
    padding: 10px;
    margin: 0;
    text-align: center;
}

.title2 {
    text-align: center;
    display: flex;
}

.title2 p {
    color: #FFFFFF;
    font-size: 35px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 10px;
    margin: 0;
    text-align: center;
}

.subtitle {
    text-align: center;
    display: flex;
    justify-content: center;
}

.subtitle p {
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    width: 100%;
    font-style: italic;
    font-weight: 800;
    padding: 0;
    margin: 0;
    /* text-transform: uppercase; */
}

.subtitle2 {
    text-align: center;
    display: flex;
    justify-content: center;
}

.subtitle2 p {
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    width: 100%;
    font-style: italic;
    font-weight: 800;
    padding: 0;
    margin: 0;
    /* text-transform: uppercase; */
}

.email {
    text-align: center;
    display: flex;
}

.email p {
    color: #FFFFFF;
    font-size: 24px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    padding: 0;
    margin: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    /* text-transform: uppercase; */
}

.footer {
    text-align: center;
    display: flex;
}

.footer p {
    color: #FFFFFF;
    font-size: 24px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    padding: 0;
    margin: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;;
    /* text-transform: uppercase; */
}

.title {
    color: #213C6D;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}


/* .rightContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
} */


@media (max-width: 800px) {
    .contactContainer {
        margin-bottom: 25px;
      }

    .infoContainer {
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-bottom: 30px;
    }

    .rightContainer,
    .middleContainer,
    .leftContainer {
        width: 100%;
        flex-direction: row;
    }
}

.logoContainer {
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 10px;
}