.musicContainer {
    padding-top: 100px;
    display: flex;
    align-items: center;    /* background-color: #BACDF6; */
    gap: 30px;
    flex-direction: row;
    background-color: #000000;
    color: #ffffff;
}

.aboutUsContainer {
    padding: 50px;
    display: flex;
    align-items: center;    /* background-color: #BACDF6; */
    /* height: 400px; */
    gap: 30px;
    flex-direction: row;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.aboutUsTitle {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 36px;
}

.aboutUsDescription {
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 10px;
    font-size: 22px;
    font-style: italic;
    font-weight: 800;
}

.albums {
    display: flex;
    flex-direction: row;
}

.albumContainer {
    padding: 50px;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-direction: column;
    background-color: #000000;
    color: #ffffff;
    width: 50%;
}

.albumLinksContainer {
    padding: 50px;
    display: flex;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    flex-direction: column;
    background-color: #000000;
    color: #ffffff;
    width: 50%;
}

.messageContainer {
    width: 50%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.messageContainer img {
    object-fit: contain;
    max-width: 100%;
}

.messageContainer p {
    color: #fff;
   }

.textContainer {
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 40px; */
    width: 40%;
    padding-top: 100px;
}

.textContainer p {
    padding: 0;
    margin: 0 ;
}

.goalText {
    font-size: 35px;
    color: #ffffff;
    margin: 0;
}

.albumTitle {
    font-size: 22px;
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;
}

.musicTitle {
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;
}

.albumText, .musicText {
    /* font-family: "Montserrat", sans-serif; */
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 10px;
    font-size: 22px;
    font-style: italic;
    font-weight: 800;
}

.musicText {
    margin-left: 140px;
}

.descriptionText {
    padding: 0 20px;
}

.descriptionText p {
    margin: 20px 0;
    font-size: 16px;
    color: #ffffff;
}

.albumCover {
    display: flex;
    justify-content: space-between;
}

.musicCarousel {
    display: none;
}

@media (max-width: 900px) {
    .musicContainer {
      flex-direction: column;
      height: auto;
      padding: 0px;
    }

    .goalText {
        font-size: 30px;
    }

    .messageContainer {
        width: 80%;
    }

    .textContainer {
        margin-top: 20px;
        width: 100%;
    }

    .albumText, .musicText {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .musicCarousel {
        display: flex;
    }
}