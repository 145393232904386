@import url(https://fonts.googleapis.com/css2?family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,100&display=swap);
body {
  margin: 0;
  font-family: "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
}

  .App {
  margin: 0;
  padding: 0;
  background: black;
}

.mobile-nav-container {
  display: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 24px;
  height: 20px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #FFFFFF;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  /* background: #373a47; */
  background: #000000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad!important;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-bottom: 10px;
  text-decoration: none!important;
  color: #b8b7ad!important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 800px) {
  .mobile-nav-container {
    display: block;
  }
  .bm-burger-button {
    display: block;
  }
}

/* Overrides */
.ant-collapse-header {
  color: white!important
}
.Header_headerContainer__-unE5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    max-height: 40px;
    background: #000000;
}

.Header_logoContainer__2GLMS {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.Header_logoContainer__2GLMS p {
    color: #FFFFFF;
    /* font-size: 35px; */
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.Header_logoContainer__2GLMS img {
    object-fit: contain;
    max-height: 40px;
}

.Header_navigationContainer__200qo {
    display: flex;
}

.Header_navigationContainer__200qo button {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;

}

.Header_landingContainer__12jy_ {
 display: flex;
 flex-direction: column;
 padding: 50px;
 position: relative;
 background: #000000;
 height: 400px;
 margin-top: 100px;
}
/* 
.landingContainer::after { 
    position:absolute;
    bottom: -50px;
    left: 0px;
    width: 100%;
    content:" ";
    background-color: #000000;
    background-size:50px 100px;
    height:50px;
    background-repeat: repeat-x;
  } */

.Header_landingImgContainer__32C3h {
    display: flex;
    justify-content: center;
    max-width: 100%;
    height: 300px;
    align-items: center;
}

.Header_landingImgContainer__32C3h img {
    object-fit: contain;
    max-width: 70%;
}

.Header_landingImgContainer__32C3h p { 
    color: #2F5597;
    /* font-size: 35px; */
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.Header_landingImgContainer__32C3h p {
    display: none;
}

.Header_landingImg__1R5Ss {
    height: 550px;
}

.Header_landingImgText__1ZNLv {
    position: absolute;
    height: 600px;
    margin-left: 150px;
}

.Header_landingImgDescription__39f5q {
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
}

@media (max-width: 800px) {
    .Header_navigationContainer__200qo {
        display: none;
    }

    .Header_landingContainer__12jy_ {
        padding-top: 0px;
        height: 300px;
    }

    .Header_logoContainer__2GLMS {
        width: 100%;
    }

    .Header_logoContainer__2GLMS img {
        padding-top: 10px;
        max-height: 70px;
    }

    .Header_logoContainer__2GLMS p {
        display: none;
        font-size: 20px;
    }

    .Header_landingImgContainer__32C3h {
        flex-direction: column;
    }

    .Header_landingImgContainer__32C3h p {
        display: flex;
        text-align: center;
        font-size: 24px;
    }

    .Header_landingImgContainer__32C3h img {
        max-width: 100%;
    }

    .Header_landingImg__1R5Ss {
        height: 450px;
    }
    
    .Header_landingImgText__1ZNLv {
        position: absolute;
        height: 300px;
        margin-left: 50px;
    }
}

.Music_musicContainer__3bap_ {
    padding-top: 100px;
    display: flex;
    align-items: center;    /* background-color: #BACDF6; */
    grid-gap: 30px;
    gap: 30px;
    flex-direction: row;
    background-color: #000000;
    color: #ffffff;
}

.Music_aboutUsContainer__284rK {
    padding: 50px;
    display: flex;
    align-items: center;    /* background-color: #BACDF6; */
    /* height: 400px; */
    grid-gap: 30px;
    gap: 30px;
    flex-direction: row;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.Music_aboutUsTitle__HYVPj {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 36px;
}

.Music_aboutUsDescription__1c4uQ {
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 10px;
    font-size: 22px;
    font-style: italic;
    font-weight: 800;
}

.Music_albums__355Re {
    display: flex;
    flex-direction: row;
}

.Music_albumContainer__25pGb {
    padding: 50px;
    display: flex;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    flex-direction: column;
    background-color: #000000;
    color: #ffffff;
    width: 50%;
}

.Music_albumLinksContainer__dFSdG {
    padding: 50px;
    display: flex;
    align-items: center;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    flex-direction: column;
    background-color: #000000;
    color: #ffffff;
    width: 50%;
}

.Music_messageContainer__3gTT2 {
    width: 50%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.Music_messageContainer__3gTT2 img {
    object-fit: contain;
    max-width: 100%;
}

.Music_messageContainer__3gTT2 p {
    color: #fff;
   }

.Music_textContainer__12DrK {
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 40px; */
    width: 40%;
    padding-top: 100px;
}

.Music_textContainer__12DrK p {
    padding: 0;
    margin: 0 ;
}

.Music_goalText__2TjXx {
    font-size: 35px;
    color: #ffffff;
    margin: 0;
}

.Music_albumTitle__2hMNV {
    font-size: 22px;
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;
}

.Music_musicTitle__3QBte {
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;
}

.Music_albumText__1PfDf, .Music_musicText__vLqIi {
    /* font-family: "Montserrat", sans-serif; */
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 10px;
    font-size: 22px;
    font-style: italic;
    font-weight: 800;
}

.Music_musicText__vLqIi {
    margin-left: 140px;
}

.Music_descriptionText__1cVpX {
    padding: 0 20px;
}

.Music_descriptionText__1cVpX p {
    margin: 20px 0;
    font-size: 16px;
    color: #ffffff;
}

.Music_albumCover__2dqGV {
    display: flex;
    justify-content: space-between;
}

.Music_musicCarousel__17Ex6 {
    display: none;
}

@media (max-width: 900px) {
    .Music_musicContainer__3bap_ {
      flex-direction: column;
      height: auto;
      padding: 0px;
    }

    .Music_goalText__2TjXx {
        font-size: 30px;
    }

    .Music_messageContainer__3gTT2 {
        width: 80%;
    }

    .Music_textContainer__12DrK {
        margin-top: 20px;
        width: 100%;
    }

    .Music_albumText__1PfDf, .Music_musicText__vLqIi {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .Music_musicCarousel__17Ex6 {
        display: flex;
    }
}
.image-li-item {
    flex: 0 0 30%;
    outline: 1px solid;
}

.image-ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
    padding-inline-start: 10px;
}

@media (max-width: 800px) {
    .image-li-item {
        flex: 0 0 100%;
        outline: 1px solid;
        margin-top: 20px;
    }
}
.Contact_contactContainer__2-H-E {
    display: flex;
    flex-direction: column;
    background: #000000;    
    padding: 40px 50px;
    /* gap: 20px; */
    align-items: center;
}

.Contact_infoContainer__2zfn4 {
    display: flex;
    width: 100%;
    padding-top: 20px;
    grid-gap: 20px;
    gap: 20px;
    /* align-items: flex-start; */
}

.Contact_leftContainer__3Aqi4,
.Contact_rightContainer__3pEkH,
.Contact_middleContainer__3bGwO {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Contact_leftContainer__3Aqi4 p,
.Contact_rightContainer__3pEkH p,
.Contact_middleContainer__3bGwO p {
    color: #2F5597;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
}

.Contact_leftContainer__3Aqi4 img,
.Contact_rightContainer__3pEkH img,
.Contact_middleContainer__3bGwO img {
    width: 50px;
    height: 50px;
}

/* .leftContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
} */

.Contact_title__16YNM {
    text-align: center;
    display: flex;
}

.Contact_title__16YNM p {
    color: #FFFFFF;
    font-size: 35px;
    font-family: "Times New Roman", Times, serif;
    font-weight: 900;
    text-transform: uppercase;
    padding: 10px;
    margin: 0;
    text-align: center;
}

.Contact_title2__31RMn {
    text-align: center;
    display: flex;
}

.Contact_title2__31RMn p {
    color: #FFFFFF;
    font-size: 35px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 10px;
    margin: 0;
    text-align: center;
}

.Contact_subtitle__3NIUn {
    text-align: center;
    display: flex;
    justify-content: center;
}

.Contact_subtitle__3NIUn p {
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    width: 100%;
    font-style: italic;
    font-weight: 800;
    padding: 0;
    margin: 0;
    /* text-transform: uppercase; */
}

.Contact_subtitle2__2BN5k {
    text-align: center;
    display: flex;
    justify-content: center;
}

.Contact_subtitle2__2BN5k p {
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    width: 100%;
    font-style: italic;
    font-weight: 800;
    padding: 0;
    margin: 0;
    /* text-transform: uppercase; */
}

.Contact_email__2WTtv {
    text-align: center;
    display: flex;
}

.Contact_email__2WTtv p {
    color: #FFFFFF;
    font-size: 24px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    padding: 0;
    margin: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    /* text-transform: uppercase; */
}

.Contact_footer__7qkm5 {
    text-align: center;
    display: flex;
}

.Contact_footer__7qkm5 p {
    color: #FFFFFF;
    font-size: 24px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    padding: 0;
    margin: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;;
    /* text-transform: uppercase; */
}

.Contact_title__16YNM {
    color: #213C6D;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}


/* .rightContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
} */


@media (max-width: 800px) {
    .Contact_contactContainer__2-H-E {
        margin-bottom: 25px;
      }

    .Contact_infoContainer__2zfn4 {
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-bottom: 30px;
    }

    .Contact_rightContainer__3pEkH,
    .Contact_middleContainer__3bGwO,
    .Contact_leftContainer__3Aqi4 {
        width: 100%;
        flex-direction: row;
    }
}

.Contact_logoContainer__126QH {
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 10px;
}
