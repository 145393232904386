.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    max-height: 40px;
    background: #000000;
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.logoContainer p {
    color: #FFFFFF;
    /* font-size: 35px; */
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.logoContainer img {
    object-fit: contain;
    max-height: 40px;
}

.navigationContainer {
    display: flex;
}

.navigationContainer button {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;

}

.landingContainer {
 display: flex;
 flex-direction: column;
 padding: 50px;
 position: relative;
 background: #000000;
 height: 400px;
 margin-top: 100px;
}
/* 
.landingContainer::after { 
    position:absolute;
    bottom: -50px;
    left: 0px;
    width: 100%;
    content:" ";
    background-color: #000000;
    background-size:50px 100px;
    height:50px;
    background-repeat: repeat-x;
  } */

.landingImgContainer {
    display: flex;
    justify-content: center;
    max-width: 100%;
    height: 300px;
    align-items: center;
}

.landingImgContainer img {
    object-fit: contain;
    max-width: 70%;
}

.landingImgContainer p { 
    color: #2F5597;
    /* font-size: 35px; */
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.landingImgContainer p {
    display: none;
}

.landingImg {
    height: 550px;
}

.landingImgText {
    position: absolute;
    height: 600px;
    margin-left: 150px;
}

.landingImgDescription {
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
}

@media (max-width: 800px) {
    .navigationContainer {
        display: none;
    }

    .landingContainer {
        padding-top: 0px;
        height: 300px;
    }

    .logoContainer {
        width: 100%;
    }

    .logoContainer img {
        padding-top: 10px;
        max-height: 70px;
    }

    .logoContainer p {
        display: none;
        font-size: 20px;
    }

    .landingImgContainer {
        flex-direction: column;
    }

    .landingImgContainer p {
        display: flex;
        text-align: center;
        font-size: 24px;
    }

    .landingImgContainer img {
        max-width: 100%;
    }

    .landingImg {
        height: 450px;
    }
    
    .landingImgText {
        position: absolute;
        height: 300px;
        margin-left: 50px;
    }
}